<template>
  <div class="commentupdate">
    <div class="commentupdate_content">
      <van-cell-group inset>
        <van-field v-model="feedback.content" rows="2" autosize type="textarea" maxlength="500" show-word-limit
          :placeholder="lang.placeholder_class" />
      </van-cell-group>
      <div class="uploaderlist">
        <van-uploader :upload-icon="'plus'" accept="image" multiple :before-read="beforeRead" :after-read="afterRead"
          max-count="9" :max-size="10 * 1024 * 1024" image-fit="contain" :before-delete="deleteImg" v-model="imageList"
          @oversize="onOversize" />
      </div>
      <div class="uploadfile">
        <van-uploader max-count="1" accept="video" multiple="false" compressed="false" preview-image="false"
          result-type="dataUrl" v-model="uploadVideo" :max-size="500 * 1024 * 1024" @oversize="VideoOversize"
          :before-read="VideobeforeRead" :after-read="VideoafterRead">
          <van-button plain type="primary">
            {{ lang.uploadVideo }} {{ feedback.video.length }}</van-button>
        </van-uploader>
        <div v-if="feedback.video.length > 0" class="right">
          <div v-for="video in feedback.video" :key="video">
            <van-icon color="#000" size="20px" name="cross" @click="deleteVideo(video)" />
            <div :id="video.url" style="width: 100%;height: 200px;"></div>
            <!-- <video
              style="width: 100%"
              controls
              autoplay
              preload="auto"
              webkit-playsinline="true"
              playsinline="true"
              x-webkit-airplay="allow"
              x5-video-player-type="h5"
              x5-video-player-fullscreen="true"
              x5-video-orientation="portraint"
              :src="video.url"
            >
            </video> -->
          </div>
        </div>
      </div>
    </div>
    <div class="footer_btn">
      <van-button type="primary" @click="confirmComments">{{
        lang.save
      }}</van-button>
    </div>
  </div>
</template>
    
<script>
import { reactive, toRefs, ref } from "vue";
import Common from "@/library/Common";
import Config from "@/library/Config";
import request from "@/library/MyRequest";
import { useRouter } from "vue-router";
import { Toast, Dialog } from "vant";
import Player from 'xgplayer';
import 'xgplayer/dist/index.min.css';
export default {
  name: "ClassEvaluation",
  components: {},
  setup() {
    const imageList = ref([]);
    const uploadVideo = ref([]);
    const mediaType = {
      IMAGE: 1, //Image
      VIDEO: 2, //Video
    };
    const common = Common;
    const router = useRouter();
    const courseData = JSON.parse(router.currentRoute.value.query.courseData);
    let { courseId } = courseData;
    const lesson_id = String(courseId);
    let state = reactive({
      lang: {
        langBtn: "",
        class_evaluation: "",
        fill_in_class_rating: "",
        publish: "",
        uploadVideo: "",
        justUpload: "",
        justUploadVideo: "",
        pictureSize: "",
        videoSize: "",
        submitSuccess: "",
        timeout: "",
        networkError: "",
        DeleteVideo: "",
        isDeleteVideo: "",
        confirm: "",
        cancel: "",
        confirm_comments: "",
        submit: "",
        yes: "",
        no: "",
        placeholder_class: "",
        save:""
      },
      fileList: [],
      content: "",
      placeholder: "1、课程内容（分点论述）\n2、学生总体表",
      feedback: {
        feedback_id: null,
        content: "",
        video: [],
      },
      tempVideo: null,
      videosObject: {}

    });
    common.initLanguage(state.lang);
    const back = function () {
      // router.go();
    };

    const getFeedback = async () => {
      var res = await request.get(
        `lesson/feedback/?lesson_id=${lesson_id}&is_teacher=1`
      );
      if (res.status == 200) {
        if (res.data.length > 0) {
          let data = res.data[0];
          state.feedback.feedback_id = data.id;
          state.feedback.content = data.content;
          for (var media of data.media) {
            if (media.media_type == mediaType.IMAGE) {
              imageList.value.push({
                url: media.url,
                media_type: media.media_type,
              });
            } else if (media.media_type == mediaType.VIDEO) {
              state.feedback.video.push({
                url: media.url,
                media_type: media.media_type,
              });
            }
          }
          setTimeout(() => {
            resetVedio()
          }, 1000);
        }
      }
    };
    const resetVedio = function () {
      state.feedback.video.map((video) => {
        state.videosObject[video.url] = new Player({
          id: video.url,
          url: video.url,
          width: '100%',
          height: '200px',
        });
      })
    }
    const onOversize = () => {
      Toast(state.lang.pictureSize);
    };
    // Toast(state.lang.pictureSize);
    const beforeRead = (File) => {
      const chenkFile = function (file) {
        return file.type == "image/png" || file.type == "image/jpeg";
      };
      if (File.length > 0) {
        //多文件
        for (let item of File) {
          if (!chenkFile(item)) {
            Toast(state.lang.justUpload);
            return false;
          }
        }
      } else {
        //单文件
        if (!chenkFile(File)) {
          Toast(state.lang.justUpload);
          return false;
        }
      }
      return true;
    };

    const afterRead = async (event) => {
      const uploadImage = async (file) => {
        var uploadFile = {
          url: null,
          name: null,
          media_type: mediaType.IMAGE,
          error: null,
        };
        const formData = new FormData();
        formData.append("file", file);
        try {
          var res = await request.upload(formData);
          if (res.status == 200) {
            uploadFile.url = res.data.url;
            uploadFile.name = file.name;
            return uploadFile;
          } else {
            uploadFile.error = `upload images https res.status:${res.status}`;
          }
          return;
        } catch (e) {
          uploadFile.error = e.message;
          console.log(e.message);
        }
        return uploadFile;
      };
      Toast.loading({
        message: state.lang.uploading,
        forbidClick: true,
        overlay: true,
      });
      if (event.length > 0) {
        for (let item of event) {
          imageList.value.pop();
        }
        for (let item of event) {
          let uploadFile = await uploadImage(item.file);
          if (uploadFile.error == null) {
            imageList.value.push(uploadFile);
          } else {
            Toast.clear();
            Toast(uploadFile.error);
          }
        }
      } else {
        imageList.value.pop();
        let uploadFile = await uploadImage(event.file);
        if (uploadFile.error == null) {
          imageList.value.push(uploadFile);
        } else {
          Toast.clear();
          Toast(uploadFile.error);
        }
      }
      Toast.clear();
    };

    const deleteImg = function (proxy) {
      return true;
    };

    const VideoOversize = () => {
      Toast(state.lang.videoSize);
    };

    const VideobeforeRead = (File) => {
      if (File.type == "video/mp4" || File.type == "video/quicktime") {
        return true;
      } else {
        Toast(state.lang.justUploadVideo);
        return false;
      }
    };

    const VideoafterRead = async (event) => {
      Toast.loading({
        message: state.lang.uploading,
        forbidClick: true,
        overlay: true,
        duration: 0,
      });
      const formData = new FormData();
      formData.append("file", event.file);
      try {
        var res = await request.upload(formData);
        if (res.status == 200) {
          var uploadFile = {
            url: res.data.url,
            media_type: mediaType.VIDEO,
          };
          state.feedback.video.push(uploadFile);
          setTimeout(() => {
            state.videosObject[res.data.url] = new Player({
              id: res.data.url,
              url: res.data.url,
              width: '100%',
              height: '200px',
            });
          }, 1000);
        }
      } catch (e) {
        Toast(state.lang.timeout);
        console.log(e);
      }
      uploadVideo.value = [];
      Toast.clear();
    };

    const deleteVideo = (video) => {
      Dialog.confirm({
        title: state.lang.DeleteVideo,
        confirmButtonText: state.lang.confirm,
        cancelButtonText: state.lang.cancel,
      }).then(() => {
        var index = state.feedback.video.indexOf(video);
        state.feedback.video.splice(index, 1);
        state.videosObject[video].destroy()
        delete state.videosObject[video]
      });
    };
    const confirmComments = () => {
      Dialog.confirm({
        title: state.lang.confirm_comments,
        confirmButtonText: state.lang.yes,
        cancelButtonText: state.lang.no,
      }).then(async () => {
        confirm();
      });
    };

    const confirm = async () => {
      var send = {
        feedback_id: state.feedback.feedback_id,
        content: state.feedback.content,
        lesson_id: lesson_id,
        media: [],
      };
      for (var img of imageList.value) {
        send.media.push(img);
      }
      for (var video of state.feedback.video) {
        send.media.push(video);
      }

      var res = null;
      try {
        res = await request.post("lesson/feedback/bulk/", send);
        if (res && res.status == 200) {
          state.feedback.feedback_id = res.data.feedback_id;
          Toast({
            forbidClick: true,
            message: state.lang.submitSuccess,
            onClose: () => {
              back();
            },
          });
        }
      } catch (e) {
        Toast(state.lang.networkError);
        console.log(e);
      }
    };

    if (!lesson_id) {
      back();
    } else {
      getFeedback();
    }
    return {
      ...toRefs(state),
      common,
      back,
      confirm,
      uploadVideo,
      imageList,
      onOversize,
      beforeRead,
      afterRead,
      VideobeforeRead,
      VideoafterRead,
      deleteImg,
      VideoOversize,
      deleteVideo,
      confirmComments,

    };
  },
};
</script>
    
<style lang="less" scoped>
.commentupdate {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  // padding: 30px 20px;
  .commentupdate_content {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
  }

  /deep/.van-cell-group {
    padding: 0;
    margin: 0;
  }

  /deep/.van-field__control {
    height: 300px !important;
  }

  /deep/.van-field {
    padding: 24px;
  }

  /deep/.van-cell {
    font-size: 32px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 44px;
  }

  /deep/.van-uploader__upload {
    width: 180px;
    height: 180px;
    background: #eaeaea;
  }

  /deep/.van-uploader__preview {
    width: 180px;
    height: 180px;
    background: #eaeaea;
  }

  /deep/.van-icon-plus {
    font-size: 80px;
    color: #666666;
  }

  /deep/.van-uploader__preview-image {
    width: 180px;
    height: 180px;
  }

  /deep/.van-uploader__preview-delete {
    width: 50px;
    height: 50px;
  }

  /deep/.van-uploader__preview-delete-icon {
    font-size: 48px;
  }

  .uploaderlist {
    margin-top: 40px;
    text-align: left;
  }

  .uploadfile {
    margin: 30px 0;

    /deep/.van-uploader,
    .van-uploader__wrapper {
      width: 100%;
    }

    /deep/.van-uploader__input-wrapper {
      width: 100%;

      .van-button {
        width: 100%;
        height: 88px;
        line-height: 88px;
        border-radius: 20px;
        font-size: 32px;
      }
    }
  }

  .footer_btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 20px 20px;
    background-color: #fff;
    box-shadow: 0px -2px 7px 0px rgba(0, 0, 0, 0.15);

    /deep/.van-button {
      width: 100%;
      height: 88px;
      border-radius: 12px;
      font-size: 32px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 44px;
      padding: 20px 40px;
      background-color: #a5907e;
      color: #fff;
      border-color: #a5907e;
    }
  }
}
</style>